import { useState, useRef, RefObject, useEffect } from 'react';

import { Box, IconButton, InputAdornment, Menu, MenuItem, Typography } from '@mui/material';

import TAGTextFieldInput from '@/components/TAGTextFieldInput';
import { BffBrandType, BffFacility, Header } from '@/types/generated';
import { useAppContext, useFacilityContext } from '@/context';
import { getBffFacilitiesByAddress } from '@/services';
import TAGSvgIcon from '@/components/TAGSvgIcon';
import TAGButton from '@/components/TAGButton';
import TAGLink from '@/components/TAGLink';
import { getOdpUrl } from '@/utils/generateODPLinks';

import {
	getSearchInputStyles,
	getDropMenuStyles,
	menuItemStyles,
	menuLinkItemStyles,
	searchWrapStyles,
	secondaryMenuButtonStyles,
} from './LocationSearch.styles';

interface IProps {
	locationBarFindOfficeUrl?: Header['locationBarFindOfficeUrl'];
	isMobile?: boolean;
	locationNavBarRef?: RefObject<HTMLDivElement>;
	searchOpened?: boolean;
}

export default function LocationSearch({
	locationBarFindOfficeUrl,
	isMobile,
	locationNavBarRef,
	searchOpened,
}: IProps) {
	const searchWrapRef = useRef<HTMLDivElement>(null);
	const searchInputRef = useRef<HTMLInputElement>(null);
	const [isMenuOpened, setMenuOpened] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState(false);
	const [facilities, setFacilities] = useState<(BffFacility | null)[]>([]);
	const { facilityBrand } = useFacilityContext();
	const { environment, config, appName, appVersion } = useAppContext();
	const url =
		environment === 'prod' ? config.services.prod.BEFFE_GRAPHQL_URL : config.services.nonprod.BEFFE_GRAPHQL_URL;
	const fetchAllLocationsOnEmptyResults = config.featureFlags?.fetchAllLocationsOnEmptyResults;

	const facilitiesListByAddress = async () => {
		const data = await getBffFacilitiesByAddress(
			facilityBrand.toString() as BffBrandType,
			searchValue,
			url,
			fetchAllLocationsOnEmptyResults,
			appName,
			appVersion
		);
		const firstFiveElements =
			data?.facilitiesByAddress && data?.facilitiesByAddress.length > 5
				? data.facilitiesByAddress.slice(0, 5)
				: [...(data?.facilitiesByAddress || [])];
		setFacilities(firstFiveElements);
	};

	const handleSearchSubmit = async () => {
		const trimmedSearchValue = searchValue.trim();

		if (trimmedSearchValue) {
			setLoading(true);
			setMenuOpened(true);
			await facilitiesListByAddress();
			setLoading(false);
		} else {
			setMenuOpened(false);
		}
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			void handleSearchSubmit();
		}
	};

	const handleSearchButtonClick = () => {
		void handleSearchSubmit();
	};

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.currentTarget.value);
	};

	const handleClose = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();

		setMenuOpened(false);
	};

	useEffect(() => {
		if (searchOpened && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [searchOpened]);

	return (
		<>
			<Box ref={searchWrapRef} sx={searchWrapStyles}>
				<TAGTextFieldInput
					fieldPlaceHolder="Search by ZIP Code"
					onChange={handleSearchInputChange}
					onKeyDown={handleKeyPress}
					value={searchValue}
					inputSx={getSearchInputStyles(isMobile)}
					inputProps={{
						'data-test-id': 'location_search_input',
						ref: searchInputRef,
					}}
					endAdornment={
						<InputAdornment position="end">
							<IconButton onClick={handleSearchButtonClick} data-test-id="location-search-icon-button">
								<Box color="neutrals.secondary" display="flex">
									<TAGSvgIcon icon="SearchFunc" size={24} />
								</Box>
							</IconButton>
						</InputAdornment>
					}
				/>
			</Box>
			<Menu
				id="header_with_location_navigation_search_result_dropdown"
				disableScrollLock={true}
				open={isMenuOpened}
				sx={getDropMenuStyles(isMobile)}
				transitionDuration={1}
				anchorEl={isMobile ? locationNavBarRef?.current : searchWrapRef.current}
				onClose={handleClose}
				{...(isMobile && { marginThreshold: 0 })}
			>
				{/* TODO: update later */}
				{loading && <Typography padding="1rem">Loading...</Typography>}
				{/* TODO: update later */}
				{!loading && !facilities.length && (
					<Typography padding="1rem">
						Sorry, no results were found in the immediate area. Please try another search.{' '}
					</Typography>
				)}
				{!loading &&
					!!facilities.length &&
					facilities.map((facility) => {
						if (!facility) return null;
						return (
							<MenuItem key={facility.code} sx={menuItemStyles}>
								<TAGLink href={getOdpUrl(facility.address, config)} linkSx={menuLinkItemStyles}>
									<Box display="flex" alignItems="center" gap="0.25rem">
										<Typography variant="bodyMediumSemiBold" color="text.primary">
											{facility.name}
										</Typography>
										<Typography variant="bodyMediumBook" color="primary.dark">
											({facility.address?.city}, {facility.address?.stateCode})
										</Typography>
									</Box>
									{facility.location?.distance && (
										<Box display="flex" alignItems="center" gap="0.25rem">
											<Typography fontSize="small" color="neutrals.secondary">
												{facility.location.distance.toFixed(1)} mi
											</Typography>
											<Box color="primary.light">
												<TAGSvgIcon icon="ArrowRightFunc" size={12} />
											</Box>
										</Box>
									)}
								</TAGLink>
							</MenuItem>
						);
					})}
				<TAGButton
					href={locationBarFindOfficeUrl || '/find-a-location'}
					variant="secondaryDefault"
					size="M"
					sx={secondaryMenuButtonStyles}
				>
					See All Locations
				</TAGButton>
			</Menu>
		</>
	);
}
