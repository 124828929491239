import {
	FooterNavigationGroup,
	HeaderNavigationLinksItem,
	NavigationGroup,
	NavigationLink,
	Page,
} from '@/types/generated';

export function itemIsPage(item: HeaderNavigationLinksItem): item is Page {
	return typeof (item as Page).path === 'string';
}

export function itemIsNavigationLink(item: HeaderNavigationLinksItem): item is NavigationLink {
	return typeof (item as NavigationLink).isInternal === 'boolean';
}

export function resolveNavLinkText(link: NavigationLink) {
	if (link.linkText) {
		return link.linkText;
	}
	if (link.isInternal && link.pageData?.__typename === 'Page') {
		return link.pageData?.breadcrumbTitle || link.pageData?.title || '';
	}
	return link.externalUrl || '';
}

export function itemIsFooterNavigationGroup(item: HeaderNavigationLinksItem): item is FooterNavigationGroup {
	if (!item) return false;

	return item.__typename === 'FooterNavigationGroup';
}

export function itemIsNavigationGroup(item: HeaderNavigationLinksItem): item is NavigationGroup {
	if (!item) return false;

	return item.__typename === 'NavigationGroup';
}
