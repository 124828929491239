import { useState } from 'react';

import { Grid, Typography, debounce, useMediaQuery, useTheme } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import TAGLink from '@/components/TAGLink';
import { resolveNavLinkText } from '@/components/NavigationLink/NavigationLink';
import { AnalyticsContext, HeaderSubNav, Maybe, NavigationLink } from '@/types/generated';
import { useAppContext, useFacilityContext, useOfficeLocationContext } from '@/context';
import { resolveEntryLink } from '@/utils/resolveButtonEntryLink/resolveButtonEntryLink';

import MobileMenu from '../../HeaderMobile/MobileMenu/MobileMenu';

import {
	desktopMainNavLinkGridStyle,
	mobileHeaderSubNavMenuTitleStyle,
	mobileMainNavLinkArrowStyle,
	mobileMainNavLinkGridStyle,
	mobileMainNavLinkStyle,
	getMainNavStyles,
} from './NavigationMenuItem.styles';
import NavigationSubMenu from './NavigationSubMenu';

interface INavigationMenuItem {
	mainNavItemLink?: Maybe<NavigationLink>;
	subNavMenu?: Maybe<HeaderSubNav>;
	index: number;
	totalMenuItems: number;
}

export const getSubNavMenuAbsolutePosition = (index: number, totalMenuItems: number) => {
	const isOddNumberofMenuItems = totalMenuItems % 2 !== 0;
	const centerMenuItemIndex = Math.floor((0 + totalMenuItems - 1) / 2);
	const isStartOfArray = index === 0;
	const isEndOfArray = index === totalMenuItems - 1;

	// center in odd number of items
	if (!isStartOfArray && !isEndOfArray && isOddNumberofMenuItems && index === centerMenuItemIndex) {
		return 'center';
	}
	// center in even number of items
	if (
		!isStartOfArray &&
		!isEndOfArray &&
		!isOddNumberofMenuItems &&
		(index === centerMenuItemIndex || index === centerMenuItemIndex + 1)
	) {
		return 'center';
	}
	if (isStartOfArray || index - 1 < centerMenuItemIndex) {
		return 'left';
	}
	return 'right';
};

export const MobileMenuLink = ({
	text = '',
	handleClick,
	showArrow = true,
	href = '#',
	openInNewTab = false,
	analyticsContext,
}: {
	handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
	href?: string;
	openInNewTab?: boolean;
	showArrow?: boolean;
	text: string | undefined;
	analyticsContext?: Maybe<AnalyticsContext>;
}) => (
	<Grid
		item
		container
		justifyContent="flex-start"
		alignItems="center"
		height="3.25rem"
		sx={mobileMainNavLinkGridStyle}
	>
		<TAGLink
			dataTestId={`header-main-nav-link-${text}`}
			linkSx={mobileMainNavLinkStyle}
			onClick={handleClick}
			href={href}
			openInNewTab={openInNewTab}
			analyticsContext={analyticsContext}
		>
			<Typography variant="bodyMediumBold" color="text.primary" sx={mobileMainNavLinkStyle}>
				{text}
				{showArrow && <ArrowForwardIosIcon sx={mobileMainNavLinkArrowStyle} />}
			</Typography>
		</TAGLink>
	</Grid>
);

export default function NavigationMenuItem({
	mainNavItemLink,
	subNavMenu,
	index,
	totalMenuItems,
}: INavigationMenuItem) {
	const [isShown, setIsShown] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleMainNavItemClick = (event: React.MouseEvent<HTMLElement>, showSubNavMenuItems: boolean) => {
		if (showSubNavMenuItems) {
			event.preventDefault();
			setAnchorEl(event.currentTarget);
		}
	};

	const handleSubNavMenuClose = () => {
		setAnchorEl(null);
	};

	const debouncedHandleMouseEnter = debounce(() => setIsShown(true), 300);

	const handleMouseLeave = () => {
		setIsShown(false);
		debouncedHandleMouseEnter.clear();
	};

	const openSubNavMenu = Boolean(anchorEl);

	const theme = useTheme();
	const isLargeDevice = useMediaQuery(theme.breakpoints.up('md'));

	const { config } = useAppContext();
	const { officeInfo } = useFacilityContext();
	const { officeInfo: officeLocationInfo } = useOfficeLocationContext();

	const linkText = mainNavItemLink?.linkText;
	const isInternal = mainNavItemLink?.isInternal ?? false;
	const pageData = mainNavItemLink?.pageData;
	const externalUrl = mainNavItemLink?.externalUrl;

	const resolvedLinktext = resolveNavLinkText({ linkText, isInternal, pageData, externalUrl });

	const href = isInternal
		? resolveEntryLink({
				path: pageData?.path || '',
				typeName: pageData?.__typename as string,
				config,
				officeInfo,
				officeLocationInfo,
		  })
		: externalUrl || '#';

	const subNavMenuAbsolutePosition = getSubNavMenuAbsolutePosition(index, totalMenuItems);

	const showSubNavMenuItems =
		subNavMenu?.headerSubNavGroupsCollection && subNavMenu?.headerSubNavGroupsCollection?.items?.length > 0;

	return isLargeDevice ? (
		<Grid
			item
			key={`desktop-header-nav-menu-item-${index}`}
			data-test-id={`desktop-header-nav-menu-item-${index}`}
			sx={desktopMainNavLinkGridStyle}
			onMouseEnter={debouncedHandleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<TAGLink
				key={`desktop-header-nav-menu-item-link-${index}`}
				dataTestId={`desktop-header-nav-menu-item-link-${index}`}
				linkText={resolvedLinktext}
				href={href}
				openInNewTab={mainNavItemLink?.openInNewTab || false}
				linkSx={{ ...getMainNavStyles(theme) }}
				analyticsContext={
					mainNavItemLink?.analyticsContext || ({ clickPosition: 'header' } as AnalyticsContext)
				}
			>
				{resolvedLinktext}
			</TAGLink>
			{isShown && showSubNavMenuItems && subNavMenu?.headerSubNavGroupsCollection && (
				<NavigationSubMenu
					subNavMenuGroups={subNavMenu.headerSubNavGroupsCollection}
					contentTilesCollection={subNavMenu.contentTilesCollection}
					subNavMenuAbsolutePosition={subNavMenuAbsolutePosition}
				></NavigationSubMenu>
			)}
		</Grid>
	) : (
		<Grid item data-test-id={`mobile-header-main-nav-link-grid-${index}`}>
			<MobileMenuLink
				text={resolvedLinktext}
				href={href}
				openInNewTab={mainNavItemLink?.openInNewTab || false}
				showArrow={showSubNavMenuItems || false}
				handleClick={(event) => handleMainNavItemClick(event, showSubNavMenuItems || false)}
				analyticsContext={
					mainNavItemLink?.analyticsContext || ({ clickPosition: 'header' } as AnalyticsContext)
				}
			/>
			<MobileMenu
				open={openSubNavMenu}
				onClose={handleSubNavMenuClose}
				isFullScreenMenu={true}
				isWithNavMenu={true}
			>
				<Grid item container direction="column" spacing={2} pl="1.25rem">
					<Grid item display={'flex'} alignItems={'center'} onClick={handleSubNavMenuClose}>
						<KeyboardArrowLeftIcon fontSize="small" sx={{ pr: '0.25rem' }} />
						<Typography variant="breadcrumbLarge" color="text.primary">
							Main Menu
						</Typography>
					</Grid>
					<Grid item sx={mobileHeaderSubNavMenuTitleStyle}>
						<Typography variant="header2" color="text.primary">
							{resolvedLinktext}
						</Typography>
					</Grid>
				</Grid>
				{subNavMenu?.headerSubNavGroupsCollection && (
					<NavigationSubMenu
						subNavMenuGroups={subNavMenu?.headerSubNavGroupsCollection}
						contentTilesCollection={subNavMenu?.contentTilesCollection}
					></NavigationSubMenu>
				)}
			</MobileMenu>
		</Grid>
	);
}
