import { BrandThemeConfig, TOfficeInfo } from '@/types';
import { FacilityFieldsFragment } from '@/graphql/__generated/sdk';

import { replaceUrlContentVars } from '../replaceUrlContentVars/replaceUrlContentVars';

export type ResolveEntryLinkArgs = {
	path?: string;
	typeName: string;
	config: BrandThemeConfig;
	officeInfo: FacilityFieldsFragment | null;
	officeLocationInfo: TOfficeInfo | null;
};

export const resolveEntryLink = ({
	path = '',
	typeName,
	config,
	officeInfo,
	officeLocationInfo,
}: ResolveEntryLinkArgs) => {
	const blogRootPath = config?.features?.blogs?.root;
	const facilityBasePath = config?.features?.otherApps?.facilityDetailsBasePath;

	switch (typeName) {
		case 'BlogDetailsPage':
			return `${blogRootPath}${path}/`;
		case 'BlogListPage':
			return `${blogRootPath}`;
		case 'FacilityDetailsPage':
		case 'ServiceDetailsPage':
			return officeInfo || (officeLocationInfo && Object.keys(officeLocationInfo).length > 0)
				? replaceUrlContentVars(path, officeInfo, officeLocationInfo, config, typeName)
				: `/${facilityBasePath}`;
		default:
			return path || '';
	}
};
